<template>
  <div id="InviteFriends">
    <!-- <div class="Back" @click="onClickLeft()">
      <van-icon name="arrow-left" size="1.25rem" />
    </div> -->
    <div class="text">
      <div class="Back" @click="onClickLeft()">
        <van-icon name="arrow-left" size="1.25rem" />
      </div>
      <p class="space-text">{{ $t('Hint.invite') }}</p>
    </div>

    <van-image
      width="100%"
      height="45vh"
      :src="require('@/assets/Img/myImg/bg_top2.png')"
    />
    <div class="content">
      <div class="Text">
        <van-image round width="3.125rem" height="3.125rem" :src="icon" />
        <div class="Text-item">
          <span>{{ $t('My.PersonalInformation.InviteFriends.Invite') }}</span>
          <span
            >{{ $t('My.PersonalInformation.InviteFriends.InvitationCode')
            }}{{ Code }}</span
          >
        </div>
      </div>
      <div class="click" @click="Copylink()">
        {{ $t('My.PersonalInformation.InviteFriends.Click') }}
      </div>
      <van-image
        width="100%"
        height="20vh"
        :src="require('@/assets/Img/myImg/bg_c.png')"
      />
    </div>
    <div class="QrCode">
      <div id="qrcode" ref="qrcode"></div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { GET_CONFIG_INFO } from '@/api'
import { count, getUserInfo, setTimeoutName, getToken } from '@/utils'
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Tab,
  Tabs,
  Image,
  NoticeBar,
  Pagination,
  Toast
} from 'vant'
import { mapGetters } from 'vuex'
export default {
  name: 'InviteFriends',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      Code: '',
      Url: '',
      icon: require('@/assets/Img/myImg/head.png'),
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    qrcode() {
      let qrcode = new QRCode('qrcode', {
        width: 173,
        height: 173,
        text: this.Url,
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    Copylink() {
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.setAttribute('value', this.Url)
      input.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      document.body.removeChild(input)
    },
    getConfigShare() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      GET_CONFIG_INFO({
        act: 'share',
      })
        .then((r) => {
          Toast.clear()
          if (r.data.ret === 1) {
            Toast.clear()
            this.$cookie.set('public-share-item', JSON.stringify(r.data.data))
            this.Code = getToken() ? this.userInfo.id : ''
            this.Url = r.data.data.text
            if (getToken()) {
              this.icon = this.userInfo.userimg
            } else {
              this.icon = require('@/assets/Img/myImg/head.png')
            }
            this.$nextTick(() => {
              this.qrcode()
            })
          } else {
            Toast.clear()
            this.$cookie.set('public-share-item', {})
          }
        })
        .catch((e) => {
          Toast.clear()
          console.error(e)
        })
    },
  },
  mounted() {
    // 开始生成二维码
  },
  created() {
    this.$store.dispatch('getUserInfo');
    this.getConfigShare()
  },
}
</script>

<style lang="less">
#InviteFriends {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  > .Back {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-size: 1rem;
    z-index: 1;
  }
  > .text {
    width: 100%;
    height: 45vh;
    position: absolute;
    z-index: 99;
    > .Back {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      font-size: 1rem;
      z-index: 1;
    }
    > p {
      margin-top: 12vh;
      text-align: center;
      font-size: calc(16rem / 16);
      color: #1a1b23;
    }
  }
  > .content {
    width: 100%;
    margin-top: -0.25rem;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    > .Text {
      width: 18.475rem;
      position: absolute;
      top: 1rem;
      z-index: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      > .Text-item {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: column;
        > span {
          font-size: 0.8rem;
        }
      }
    }
    > .click {
      width: 12rem;
      height: 3rem;
      line-height: 3rem;
      font-size: 1.2rem;
      text-align: center;
      color: #e82121;
      position: absolute;
      z-index: 1;
      bottom: 0;
    }
  }
  > .QrCode {
    width: 100%;
    height: 33.5vh;
    display: flex;
    padding-top: 0.8rem;
    flex-direction: row;
    justify-content: center;
    background-color: #d90f18;
  }
}
</style>